import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
const ProductDesign = React.lazy(() => import("../components/ProductDesign"))

export default function ServicesPage() {
  return (
    <Layout>
      <Helmet>
        <link rel="canonical" href="https://codefulcrum.com/product-design/" />
      </Helmet>

      <React.Suspense fallback={<div />}>
        <ProductDesign />
      </React.Suspense>
    </Layout>
  )
}
